// Font Families
$headline-font-family:          'Mango Grotesque','Humane', Humane, Impact, serif;
$subheadline-font-family:       ivypresto-display;
$body-font-family:              "Geist", serif;
$mono-font-family:              "Geist Mono", serif;

$font-families: (
    "headline": $headline-font-family,
    "subheadline": $subheadline-font-family,
    "body": $body-font-family,
    "mono": $mono-font-family,
) !default;


// Colors
$primary:                       #1C1F22;
$text-black:                    #6C757D;
$muted:                         #E9ECEF;
$light:                         #F8F9FA;
$white:                         #ffffff;
$black:                         #121417;
$subtle:                        #c4c4c4;

$theme-colors: (
  "primary": $primary,
  "text-black": $text-black,
  "muted": $muted,
  "light": $light,
  "white": $white,
  "black": $black,
  "subtle": $subtle
);




// Breakpoints
$breakpoint-sm:	576px;
$breakpoint-md:	768px;
$breakpoint-lg:	992px;
$breakpoint-xl:	1200px;

$breakpoints: (
    "sm": $breakpoint-sm,
    "md": $breakpoint-md,
    "lg": $breakpoint-lg,
    "xl": $breakpoint-xl,
);

:root {
    //Colors
    @each $name, $color in $theme-colors {
        --color-#{$name}: #{$color};
    }

    //Text
    @each $name, $color in $theme-colors {
        --text-#{$name}: var(--color-#{$name});
    }

    //Backgrounds
    @each $name, $color in $theme-colors {
        --bg-#{$name}: var(--color-#{$name});
    }

    //Fonts
    @each $name, $value in $font-families {
        --font-#{$name}: #{$value};
    }

    //Breakpoints 
    @each $name, $value in $breakpoints {
        --breakpoint-#{$name}: #{$value};
    }

    --body-text: var(--text-text-black);
    --heading-text: var(--text-primary);
    --border-color: var(--color-subtle);
    --nav-bg: #{$light};
    --nav-logo-fill: #{$primary};
    --texture-text-color: #{$light};
    --button-border: #{$primary};
    --button-background-static: transparent;
    --button-background-hover: var(--button-border);
    --button-text-static: #{$primary};
    --button-text-hover: #{$white};
    --card-bg: var(--color-light);
}