body {
    color: var(--body-text);
    font-family: var(--font-body);
    --webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    color: var(--body-text);
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

h1, h2, .h1, .h2, .fontsize\:1, .fontsize\:2 {
    color: var(--heading-text);
    font-family: var(--font-headline);
    font-weight: 300;
    text-transform: uppercase;
    line-height: .8;
}

h1, .h1, .fontsize\:1 {
    font-size: clamp(8rem, 5vw + 1rem, 9rem);
}

h3, h4, h5, h6, .h3, .h4, .h5, .h6, .fontsize\:3, .fontsize\:4, .fontsize\:5, .fontsize\:6 {
    color: var(--heading-text);
    font-family: var(--font-subheadline);
    font-weight: 300;
}

.eyebrow {
    font-family: var(--font-body);
    text-transform: uppercase;
    font-size: 12px;
    color: var(--body-text);
    opacity: .5;
    letter-spacing: 2px;
}

.number\:marker {
    height: 38px;
    width: 38px;
    border-radius: 100000rem;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 0;
    }

}

.title\:texture {
    line-height: .8;
    margin-bottom: 0;
    font-weight: 300;
    font-family: var(--font-headline);
    color: var(--texture-text-color);
}

.title\:texture::before {
	content: "";
	display: table;
	margin-bottom: calc(-0.5lh + 0.42em);
}
.title\:texture::after {
	content: "";
	display: table;
	margin-bottom: calc(-0.5lh + 0.29em);
}

.list\:unstyled {
    list-style: none;
    padding: 0;
}

.section-title {
    position: relative;
    min-height: 40vw;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        margin-bottom: 0;
    }

    .title\:texture {
        opacity: 0;
        font-size: 40vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 0;
        animation: titleTexture 1s forwards;
    }

    h2.h1 {
        opacity: 0;
        animation: titleTexture 1s .5s ease-in-out forwards;
    }
}
  