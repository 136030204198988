@use "variables" as *;

body .bg\:primary, body.bg\:primary, .bg\primary {
    background-color: var(--bg-primary);
    --body-text: var(--color-white);
    --heading-text: var(--text-light);
    --border-color: var(--color-black);
    --nav-bg: var(--bg-black);
    --nav-logo-fill: var(--color-light);
    --texture-text-color: var(--text-black);
    --button-border: #{$white};
    --button-background-static: transparent;
    --button-background-hover: var(--button-border);
    --button-text-static: #{$white};
    --button-text-hover: #{$primary};
    --card-bg: var(--bg-black);
}

body .bg\:dark, body.bg\:dark, .bg\:dark, body .bg\:black, body.bg\:black, .bg\:black {
    background-color: var(--bg-black);
    --body-text: var(--color-white);
    --heading-text: var(--text-light);
    --border-color: var(--color-primary);
    --nav-bg: var(--bg-primary);
    --nav-logo-fill: var(--color-light);
    --texture-text-color: var(--text-primary);
    --button-border: #{$white};
    --button-background-static: transparent;
    --button-background-hover: var(--button-border);
    --button-text-static: #{$white};
    --button-text-hover: #{$primary};
    --card-bg: var(--bg-primary);
}

body .bg\:light, body.bg\:light, .bg\:light {
    background-color: var(--bg-light);
    --border-color: var(--color-subtle);
    --nav-bg: var(--bg-white);
    --texture-text-color: var(--text-white);
    --body-text: var(--text-text-black);
    --heading-text: var(--text-primary);
    --card-bg: var(--bg-white);
}

.bg\:white {
    background-color: var(--bg-white);
    //Colors
    @each $name, $color in $theme-colors {
        --color-#{$name}: #{$color};
    }

    //Text
    @each $name, $color in $theme-colors {
        --text-#{$name}: var(--color-#{$name});
    }

    //Backgrounds
    @each $name, $color in $theme-colors {
        --bg-#{$name}: var(--color-#{$name});
    }

    //Fonts
    @each $name, $value in $font-families {
        --font-#{$name}: #{$value};
    }

    //Breakpoints 
    @each $name, $value in $breakpoints {
        --breakpoint-#{$name}: #{$value};
    }

    --body-text: var(--text-text-black);
    --heading-text: var(--text-primary);
    --border-color: var(--color-subtle);
    --nav-bg: #{$light};
    --nav-logo-fill: #{$primary};
    --texture-text-color: #{$light};
    --card-bg: var(--color-light);
}