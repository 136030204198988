@use "variables" as *;

.jumbotron\:home {
    position: relative;
    min-height: 95vh;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jumbotron\:home .title\:texture {
    position: absolute;
    font-family: var(--font-headline);
    text-transform: uppercase;
    line-height: .8;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
    opacity: 0;
    font-size: 40vw;
    color: var(--texture-text-color);
    animation: titleTexture 1s forwards;
  }
  .jumbotron\:home h1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 6rem;
    text-align: center;
    width: 100%;
  }
  @media screen and (max-width: $breakpoint-lg) {
    .jumbotron\:home h1 {
      flex-direction: column;
    }
  }
  .jumbotron\:home h1 span.line\:one, .jumbotron\:home h1 span.line\:two {
    opacity: 0;
  }
  .jumbotron\:home h1 span.line\:one {
    animation: titleTexture 1s 1s ease-in-out forwards;
  }
  .jumbotron\:home h1 span.line\:two {
    animation: titleTexture 1s 1.75s ease-in-out forwards;
  }
  .jumbotron\:home .h5 {
    opacity: 0;
    animation: titleTexture 1s 2s ease-in-out forwards;
  }
  .jumbotron\:home .image\:wrapper {
    display: inline-flex;
    width: 0px;
    height: 75px;
    border-radius: 50rem;
    margin-right: 1rem;
    margin-left: 1rem;
    transform: translateY(-10%);
    opacity: 1;
    animation: imageWrapper 1s 2s ease-in-out forwards;
    overflow: hidden;

    @media screen and (max-width: $breakpoint-md ) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

  }
  .jumbotron\:home .image\:wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
  
  @keyframes titleTexture {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes imageWrapper {
    0% {
      width: 0;
    }
    100% {
      width: 150px;
    }
  }