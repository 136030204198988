@use "variables" as *;

body {
    padding-left: 80px;

    @media screen and (max-width: $breakpoint-md) {
        padding-left: 0px;
        padding-top: 85px;
    }

}

main {
    margin-bottom: 100vh;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: relative;
    z-index: 1;
}

footer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow-x: hidden;
    overflow-y: hidden;

    .title\:texture {
        font-size: 50vw;
        color: var(--texture-text-color);
        font-family: var(--font-headline);
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }

}

.logo {
    svg {
        fill: var(--nav-logo-fill);
        width: 48px;
    }
}

.border\:top {
    border: 0;
    border-top: 1px solid var(--border-color);
}

.border\:right {
    border: 0;
    border-right: 1px solid var(--border-color);
}

.border\:bottom {
    border: 0;
    border-bottom: 1px solid var(--border-color);
}

.border\:left {
    border: 0;
    border-left: 1px solid var(--border-color);
}

.image\:pill {
    width: 200px;
    height: 100px;
    overflow: hidden;
    border-radius: 50rem;
    margin: 0;
    padding: 0;
    margin: 1rem 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

}

.button {
    display: inline-block;
    border: 1px solid var(--button-border);
    background-color: var(--button-background-static);
    color: var(--button-text-static);
    font-weight: 400;
    font-size: 14px;
    padding: .75rem 1rem;
    text-transform: uppercase;
    border-radius: 10000rem;
    transition: .4s ease-in-out;

    &:hover {
        color: var(--button-text-hover);
        background-color: var(--button-background-hover);
    }

}

.chip {
    padding: 0.5rem;
    border-radius: 50rem;
    border: 1px solid var(--border-color);
    color: var(--body-text);
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
  }
  .chip\:list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: row;
  }
  .chip\:list li {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

.figure\:rounded {
    overflow: hidden;
    border-radius: 1rem;
    padding: 0;
    margin: 0;

    img {
        margin: 0;
    }

}

.rounded {
    border-radius: 1rem;
}

.featured\:image {
    height: 500px;
    overflow: hidden;
    @extend .rounded;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

}

.screen\:scroll {
    --screen-scroll-width: 100%;
    --screen-scroll-height: 650px;
    @extend .rounded;
    overflow: hidden;
    width: var(--screen-scroll-width);
    height: var(--screen-scroll-height);
    padding: 0;
    margin: 0;
    background-color: $white;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    img {
        width: 100%;
        height: auto;
        animation: scrollImage 20s ease-in-out infinite alternate; // Animation
    }

}

@keyframes scrollImage {
    0% {
      transform: translateY(0);
    }
    50% {
        transform: translateY(calc(1 * (var(--screen-scroll-height) - 100%)));
    }
    100% {
        transform: translateY(0);
    }
  }

.card {
    @extend .rounded;
    border-color: var(--border-color);
    background: var(--card-bg);
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    p {
        font-weight: 400;
        color: var(--body-text);
    }

    &\:image {
        height: 250px;
        width: 100%;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

    }

    &\:content {
        padding: .75rem;
    }
}

.form\:group {
    input[type="text"], input[type="email"], textarea {
        background-color: var(--card-bg);
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 1rem;
        margin-bottom: 1.5rem;
        width: 100%;
        border-bottom: 2px solid var(--border-color);
        color: var(--body-text);

        &::placeholder {
            color: var(--body-text);
            font-weight: 400;
        }

    }
}