@font-face {
    font-family: 'Mango Grotesque';
    src: url('/assets/fonts/Humane-Light.eot');
    src: url('/assets/fonts/Humane-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Humane-Light.woff2') format('woff2'),
        url('/assets/fonts/Humane-Light.woff') format('woff'),
        url('/assets/fonts/Humane-Light.ttf') format('truetype'),
        url('/assets/fonts/Humane-Light.svg#Humane-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mango Grotesque';
    src: url('/assets/fonts/Humane-Thin.eot');
    src: url('/assets/fonts/Humane-Thin.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Humane-Thin.woff2') format('woff2'),
        url('/assets/fonts/Humane-Thin.woff') format('woff'),
        url('/assets/fonts/Humane-Thin.ttf') format('truetype'),
        url('/assets/fonts/Humane-Thin.svg#Humane-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mango Grotesque';
    src: url('/assets/fonts/Humane-Regular.eot');
    src: url('/assets/fonts/Humane-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Humane-Regular.woff2') format('woff2'),
        url('/assets/fonts/Humane-Regular.woff') format('woff'),
        url('/assets/fonts/Humane-Regular.ttf') format('truetype'),
        url('/assets/fonts/Humane-Regular.svg#Humane-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mango Grotesque Med';
    src: url('/assets/fonts/Humane-Medium.eot');
    src: url('/assets/fonts/Humane-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Humane-Medium.woff2') format('woff2'),
        url('/assets/fonts/Humane-Medium.woff') format('woff'),
        url('/assets/fonts/Humane-Medium.ttf') format('truetype'),
        url('/assets/fonts/Humane-Medium.svg#Humane-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mango Grotesque SemBd';
    src: url('/assets/fonts/Humane-SemiBold.eot');
    src: url('/assets/fonts/Humane-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Humane-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Humane-SemiBold.woff') format('woff'),
        url('/assets/fonts/Humane-SemiBold.ttf') format('truetype'),
        url('/assets/fonts/Humane-SemiBold.svg#Humane-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mango Grotesque';
    src: url('/assets/fonts/Humane-Bold.eot');
    src: url('/assets/fonts/Humane-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Humane-Bold.woff2') format('woff2'),
        url('/assets/fonts/Humane-Bold.woff') format('woff'),
        url('/assets/fonts/Humane-Bold.ttf') format('truetype'),
        url('/assets/fonts/Humane-Bold.svg#Humane-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mango Grotesque ExtLt';
    src: url('/assets/fonts/Humane-ExtraLight.eot');
    src: url('/assets/fonts/Humane-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Humane-ExtraLight.woff2') format('woff2'),
        url('/assets/fonts/Humane-ExtraLight.woff') format('woff'),
        url('/assets/fonts/Humane-ExtraLight.ttf') format('truetype'),
        url('/assets/fonts/Humane-ExtraLight.svg#Humane-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

