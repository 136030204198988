@use "variables" as *;

.nav\:bar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--nav-bg);
    border-right: 1px solid var(--border-color);
    z-index: 1000000000000;
}

#nav\:panel\:toggle {
    display: block;
    width: 48px;
    height: 48px;
    color: var(--body-text);
    background-color: transparent;
    box-shadow: none;
    border: none;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
}

.nav\:logo {
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;
    color: transparent;
    svg {
        width: 48px;
        fill: var(--nav-logo-fill);
    }
}

.nav\:panel {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50vw;
    z-index: 100000;
    background-color: var(--nav-bg);
    padding-left: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-100vw);
    transition: .5s ease-in-out;

    &.active {
        transform: translate(0);
    }

    ul {
        padding: 0;
        list-style: none;
        width: 100%;
    }

    a {
        text-decoration: none;
    }

}

@media screen and (max-width: $breakpoint-md) {
    .nav\:bar {
        top: 0;
        right: 0;
        left: 0;
        bottom: auto;
        width: 100vw;
        flex-direction: row;
    }

    .nav\:panel {
        width: 100vw;
        padding-left: 0;
        padding: 1rem;
    }
}