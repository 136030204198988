.project\:featured {
    .icon\:arrow {
        height: 40px;
        width: 40px;
        overflow: hidden;
    }
    .icon\:arrow\:inner {
        display: flex;
        flex-direction: row;
        width: 80px;
        transition: all 0.3s ease-in-out;
        transform: translateX(-40px);
    }
     .icon\:arrow svg {
        height: 40px;
        width: 40px;
        fill: var(--body-text);
        transition: all 0.3s ease-in-out;
    }
    &:hover .icon\:arrow .icon\:arrow\:inner {
        transform: translateX(0);
    }
}